
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-leads::v-deep {
    background-color: $white;
    color: $black;

    .proposal-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .v-image {
            line-height: 180px;
            max-height: 180px;
            margin-bottom: 40px;
        }
    }

    .offer-table {
        width: 100%;
        font-size: 150%;
        border-collapse: collapse;
        border: 3px solid $clientcabin-blue;
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);

        th {
            color: white;
            background-color: $clientcabin-blue;
            border: none;
        }

        th,
        td {
            padding: 30px 40px;
        }

        tr:nth-child(odd) {
            background-color: #fff;
        }
        tr:nth-child(even) {
            background-color: #eee;
        }

        td + td {
            text-align: center;
        }
    }

    .multiline {
        .v-btn__content {
            flex-direction: column;
        }
    }
}

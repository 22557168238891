/*
 * For not so obvious color code, i use https://chir.ag/projects/name-that-color
 * to get the name.
 */

$primary-color: #fe0d66;
$secondary-color: #59748a;
$secondary-color-light: #85a5c2;
$tertiary-color: #a6bed2;
$main-background: #f8f8f8;
$black: #000000;
$semi-black: #1b1b1b;
$light-black: #acacac;
$grey-light: #fbfbfe;
$grey-medium-light: #d8d8d8;
$grey-light-darker: #f0f0f0;
$mercury-solid: #e5e5e5;
$concrete-solid: #f2f2f2;
$concrete-light: #f9f9f9;
$blueish: #f4faff;
$boulder: #797979;
$white: #ffffff;
$dark-orange: #ff8a5b;
$light-orange: #fceade;
$turquoise: #25ced1;
$light-turquoise: #c5f9fa;
$faded-blue: #d9ecfc;
$grey-blue: #70849b;
$pickled-bluewood: #364d60;
$rocketium-blue: #006fb4;
$stock-image-background: #efefef;
$error: #ff5252;
$bonjour: #dfdbdb;
$white: #ffffff;
$rajah: #f9bf76;
$seashell-solid: #f1f1f1;
$vuetify-grey: #e0e0e0;
$doger-blue: #3591fb;
$ebb: #f2eeee;
$toxic-yellow: #fffd74;
$dark-blue: #2b478b;
$darker-blue: #223b86;
$intense-blue: #3145f5;
$electric-blue: #0858a0;
$msn-blue: #1ca9f9;
$clientcabin-blue: #3692d2;
$clientcabin-darkblue: #0c2046;
$clientcabin-navy: #0057ae;
$clientcabin-apple: #5fc851;
$secondary-color-10: #59748a1a;

$ampcolors: (
    'primary-color': $primary-color,
    'secondary-color': $secondary-color,
    'secondary-color-light': $secondary-color-light,
    'tertiary-color': $tertiary-color,
    'main-background': $main-background,
    'black': $black,
    'semi-black': $semi-black,
    'light-black': $light-black,
    'grey-light': $grey-light,
    'grey-medium-light': $grey-medium-light,
    'grey-light-darker': $grey-light-darker,
    'mercury-solid ': $mercury-solid,
    'concrete-solid': $concrete-solid,
    'concrete-light': $concrete-light,
    'blueish': $blueish,
    'boulder': $boulder,
    'dark-orange': $dark-orange,
    'light-orange': $light-orange,
    'turquoise': $turquoise,
    'light-turquoise': $light-turquoise,
    'faded-blue': $faded-blue,
    'grey-blue': $grey-blue,
    'pickled-bluewood': $pickled-bluewood,
    'rocketium-blue': $rocketium-blue,
    'stock-image-background': $stock-image-background,
    'error': $error,
    'bonjour': $bonjour,
    'white': $white,
    'rajah': $rajah,
    'seashell-solid': $seashell-solid,
    'vuetify-grey': $vuetify-grey,
    'toxic-yellow': $toxic-yellow,
    'dark-blue': $dark-blue,
    'darker-blue': $darker-blue,
    'doger-blue': $doger-blue,
    'ebb': $ebb,
    'intense-blue': $intense-blue,
    'electric-blue': $electric-blue,
    'msn-blue': $msn-blue,
    'clientcabin-blue': $clientcabin-blue,
    'clientcabin-darkblue': $clientcabin-darkblue,
    'clientcabin-navy': $clientcabin-navy,
    'clientcabin-apple': $clientcabin-apple,
    'secondary-color-10': $secondary-color-10
);
